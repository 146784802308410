import { constants, http, metadata } from 'lib_ui-services';
import pThrottle from 'p-throttle';

const { notificationTypes } = constants;

// We are rate limited to 50 requests per path per minute
const MAX_REQUEST_PER_TIME_FRAME = 50;
const REQUEST_RATE_TIME_FRAME = 60000; // 1 minute in ms
const BATCH_SIZE = 100;

export default {
    verb: 'doingCreate',
    namespace: 'import',
    relation: 'import',
    description: 'Doing the actual import, by sending the data over to the server',
    // this is the actual logic:
    logic: doingCreate,
    // error handling to avoid the progress staying on the screen:
    onError
};

const _p = {
    pThrottle,
    metadata
};
export const _private = _p;

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function doingCreate({ data, dispatch, log }) {
    const { newRecord } = data;
    const { _id, title, data: parsedData, fileSize, foreignNamespace, foreignRelation } = newRecord;
    const url = `/api/${foreignNamespace}/${foreignRelation}/${_id}/import`;

    // calculate the average chunk size
    const totalRecords = parsedData.length;
    if (totalRecords === 0) return;

    const averageRecordSize = fileSize / totalRecords;
    log.info(`Importing ${totalRecords} records into ${foreignNamespace}:${foreignRelation}.`);

    const titleAlternative = _p.metadata.getTitleAlternative(foreignNamespace, foreignRelation, 'title');
    const sendBatch = createThrottledBatchSender(url);

    const { showProgress, closeProgress } = createProgressHandler(dispatch, title, totalRecords);
    const failed = [];

    await showProgress(0, 'Starting import process');
    toggleSpinner(dispatch, true);

    for (let chunkIndex = 0; chunkIndex * BATCH_SIZE < totalRecords; chunkIndex++) {
        const chunk = parsedData.slice(chunkIndex * BATCH_SIZE, (chunkIndex + 1) * BATCH_SIZE);
        const batch = createBatch(chunk, newRecord, chunkIndex, averageRecordSize, totalRecords);

        try {
            const result = await sendBatch(batch);
            if (result?.[0]?.failed?.length) {
                // include more detail about any failed import records so it be used later to show the user
                failed.push(...result[0].failed.map(f => ({ ...f, title: f[titleAlternative] })));
            }
        } catch (error) {
            log.error(`Error importing batch ${chunkIndex + 1}:`, error);
        }

        await showProgress(
            Math.min((chunkIndex + 1) * BATCH_SIZE, totalRecords),
            `Imported ${(chunkIndex + 1) * BATCH_SIZE} out of ${totalRecords} records`
        );
    }

    await closeProgress(`Completed import of ${totalRecords} records`);
    toggleSpinner(dispatch, false);

    return {
        ...data,
        result: {
            title,
            totalRecords,
            failed
        }
    };
}

function onError({ error, dispatch }) {
    closeProgressDialog(dispatch);
    throw error;
}

/**
 * Utility functions
 */
function createThrottledBatchSender(url) {
    // throttle at 1 per part of interval, rather than MAX_REQUEST_PER_TIME_FRAME as limit and REQUEST_RATE_TIME_FRAME as interval
    // to avoid an initial surge and avoid any risk of hitting rate limits:
    const throttle = _p.pThrottle({
        limit: 1,
        interval: REQUEST_RATE_TIME_FRAME / MAX_REQUEST_PER_TIME_FRAME
    });
    return throttle(batch => http.post(url, batch));
}

function createProgressHandler(dispatch, title, totalRecords) {
    const showProgress = progressNotifier(dispatch, `Importing ${title}`, totalRecords);
    return {
        showProgress,
        closeProgress: async message => {
            await showProgress(totalRecords, message);
            closeProgressDialog(dispatch);
        }
    };
}

function createBatch(chunk, newRecord, chunkIndex, averageRecordSize, totalRecords) {
    const { _id, title, fileSize, foreignNamespace, foreignRelation, ...importDetails } = newRecord;
    const chunkSize = Math.round(chunk.length * averageRecordSize);
    const progress = Math.round((chunkIndex * BATCH_SIZE + chunk.length) * averageRecordSize);

    // the server uses chunk size rather than progress,
    // as theoretically there is a chance that it might process these batches in parallel/out of order
    // chunkSize will allow it to simply add the current value to whatever was stored (rather than replace it)
    return {
        _id,
        title,
        namespace: foreignNamespace,
        relation: foreignRelation,
        ...importDetails,
        data: chunk,
        fileSize,
        totalRecords,
        progress,
        chunkSize
    };
}

function toggleSpinner(dispatch, isSpinning) {
    const message = isSpinning ? 'Removing' : 'Removal Complete';
    dispatch(
        { busy: isSpinning, source: 'httpSync', message, type: notificationTypes.SYNC_BUSY },
        { verb: 'pop', namespace: 'application', relation: 'notification' }
    );
}

function progressNotifier(dispatch, title, total) {
    return (current, description) =>
        dispatch(
            { mainTitle: 'Importing', description, title, current, total },
            { verb: 'update', namespace: 'application', relation: 'progress' }
        );
}

function closeProgressDialog(dispatch) {
    return dispatch({ mainTitle: 'Importing' }, { verb: 'reset', namespace: 'application', relation: 'progress' });
}
