/**
 * Selection Retention:
 *  Scope: Local, PerRoute, Global
 *  Visibility: PerBrowser (later: PerUser, PerTenant)
 *  Duration: Request, Session, Always
 * @typedef {import('lib_ui-services/src/constants/retention')} Retention
 *
 */
import { localStorage, constants, sessionStorage } from 'lib_ui-services';
import { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import useRouter from './useRouter';
import useSimpleChangeObserver, { publishGlobalChangeDirect } from './useSimpleChangeObserver';
import useTimeout from './useTimeout';

const retention = constants.retention;
const { SCOPE, VISIBILITY, DURATION } = retention;
const _p = { localStorage, sessionStorage, useRouter };
const CHANGE_TYPE = 'useBbStateChange';
export const _private = _p;
/**
 * This acts like useState, but retains the state according to the retention settings specified.
 * Scopes of perTenant and perUser are not yet implemented.  When they are, it might
 * (depending on the implementation) be important to change the usePrefix parameter of
 * the localStorage methods to reflect the scope.
 * Be aware that duration 'ALWAYS' state can cross use cases and use a stateName that
 * includes an id (for instance) to avoid collisions.
 * @param {any} defaultValue - the default value to store/retrieve
 * @param {string} stateName - name of state to store/retrieve
 * @param {Retention} retention
 * @returns {[any, function, boolean]} array containing the current state, the function to change it, and a boolean indicating whether the state is ready
 * to be used. The last value is only applicable for DURATION.ALWAYS because the storage operation in localForage is async.
 */
function useBbState(defaultValue, stateName, retention /*, debugText /* useful for debugging state changes */) {
    const {
        id,
        scope = SCOPE.LOCAL,
        visibility = VISIBILITY.PER_BROWSER,
        duration = DURATION.REQUEST
    } = retention || {};
    const router = _p.useRouter();
    const storageKey = useMemo(
        () => scopeToKey(scope, stateName, id, router.getRouteStateKey()),
        [scope, id, stateName, router]
    );

    const startingState = useMemo(() => {
        if (duration === DURATION.SESSION) {
            return _p.sessionStorage.getKey(storageKey, defaultValue);
        }
        return defaultValue;
    }, [defaultValue, storageKey, duration]);

    const [ready, setReady] = useState([DURATION.REQUEST, DURATION.SESSION].includes(duration) ? true : false);
    const [state, _setState] = useState(startingState);

    // Coordinate state changes between components using the same storage key
    const { onChange, publishChange } = useSimpleChangeObserver(true);
    const setState = useCallback(
        newState => {
            _setState(oldState => {
                // Useful for debugging observations of state changes
                // console.log(debugText, 'useBbState.setState', { oldState, newState });
                if (isEqual(oldState, newState)) return oldState;
                publishChange(CHANGE_TYPE, { storageKey, newState });
                return newState;
            });
        },
        [publishChange, storageKey]
    );

    // Because a change to useBbState in one component can update the useBbState
    // in another component (assuming they have the same storage key), this
    // makes the subscription for the change async, avoiding an update to the
    // second component while the first is still rendering.
    const asyncStateUpdate = useTimeout(
        newState => {
            if (allowStateChange.current) {
                _setState(newState);
            }
        },
        [],
        0
    );

    useEffect(() => {
        onChange((_changeType, { storageKey: key, newState }) => {
            if (_changeType === CHANGE_TYPE && key === storageKey) {
                // Useful for debugging observations of state changes
                // console.log(debugText, 'useBbState.onChange', { storageKey, newState });
                asyncStateUpdate(newState);
            }
        });
    }, [onChange, storageKey, asyncStateUpdate]);

    //validate and throw errors as needed:
    validate(visibility, duration, scope, id);

    // Don't rerender for new refs of an equal object
    const firstDefaultValue = useRef(defaultValue);

    // Prevent state updates after this is destroyed.
    const allowStateChange = useRef(true);
    useEffect(
        () => () => {
            allowStateChange.current = false;
        },
        []
    );

    // Retrieve the state from localStorage if necessary
    useEffect(() => {
        async function doAsync() {
            const existingState = await _p.localStorage.getKey(storageKey, undefined, firstDefaultValue.current, false);
            if (allowStateChange.current) {
                setState(existingState);
                setReady(true);
            }
        }
        if (duration === DURATION.ALWAYS) doAsync();
    }, [storageKey, duration, setState]);

    // Retrieve the state from sessionStorage if necessary
    useEffect(() => {
        if (duration !== DURATION.SESSION) return;

        const existingState = _p.sessionStorage.getKey(storageKey, firstDefaultValue.current);
        if (allowStateChange.current) {
            setState(existingState);
        }
    }, [storageKey, duration, setState]);

    const proxySetPersistentState = useCallback(
        async newState => {
            setReady(false);
            let calculatedState = newState;
            if (typeof newState === 'function') {
                const prev = await _p.localStorage.getKey(storageKey, undefined, firstDefaultValue.current, false);
                if (allowStateChange.current) {
                    calculatedState = newState(prev);
                }
            }
            if (allowStateChange.current) {
                await _p.localStorage.setKey(storageKey, calculatedState, undefined, false);
                if (allowStateChange.current) {
                    setState(calculatedState);
                    setReady(true);
                }
            }
        },
        [storageKey, setState]
    );
    const proxySetSessionState = useCallback(
        async newState => {
            let calculatedState = newState;
            if (typeof newState === 'function') {
                const prev = _p.sessionStorage.getKey(storageKey);
                if (allowStateChange.current) {
                    if (prev == null) {
                        calculatedState = newState(firstDefaultValue.current);
                    } else {
                        calculatedState = newState(prev);
                    }
                }
            }
            if (allowStateChange.current) {
                _p.sessionStorage.setKey(storageKey, calculatedState);
                if (allowStateChange.current) {
                    setState(calculatedState);
                }
            }
        },
        [storageKey, setState]
    );

    let proxySetState;
    if (duration === DURATION.ALWAYS) {
        proxySetState = proxySetPersistentState;
    } else if (duration === DURATION.SESSION) {
        proxySetState = proxySetSessionState;
    } else if (duration === DURATION.REQUEST) {
        proxySetState = setState;
    }

    // This is an edge case
    // If the storage key changes and this renders, it means the route changed, but the
    // same component reference is rendering.  This is only going to be for things like dynamic reports
    // generated for multiple inventories where the same page ref (with the same hNode.id) is
    // rendering, but a different state is needed because the route search/query params changed.
    // In that case, to avoid this giving an outdated result on next render, get the
    // startingState -- which should be the sessionState that was last stored for this
    // storage key.
    const previousStorageKey = useRef();
    if (storageKey !== previousStorageKey.current) {
        previousStorageKey.current = storageKey;
        return [startingState, proxySetState, ready];
    }
    return [state, proxySetState, ready];
}

/**
 * This allows setting data "directly" into the appropriate places without using a hook,
 * but still getting the protection of generating keys in the same fashion as the hook
 * @param {any} value - the new value to store
 * @param {string} stateName - name of state to store
 * @param {Retention} retention
 * @param {string} [routeStateKey] - routeStateKey, only required when retention.scope === SCOPE.PER_ROUTE
 */
useBbState.setDirect = async function setDirect(value, stateName, retention, routeStateKey) {
    const {
        id,
        scope = SCOPE.LOCAL,
        visibility = VISIBILITY.PER_BROWSER,
        duration = DURATION.REQUEST
    } = retention || {};
    const storageKey = scopeToKey(scope, stateName, id, routeStateKey);

    //validate and throw errors as needed:
    validate(visibility, duration, scope, id);

    const proxySetPersistentState = async newState => {
        let calculatedState = newState;
        if (typeof newState === 'function') {
            const prev = await _p.localStorage.getKey(storageKey, undefined, undefined, false);
            calculatedState = newState(prev);
        }
        if (calculatedState == null) {
            _p.localStorage.deleteKey(storageKey, undefined, false);
        } else {
            _p.localStorage.setKey(storageKey, calculatedState, undefined, false);
        }
        publishGlobalChangeDirect(CHANGE_TYPE, { storageKey, newState: calculatedState });
    };

    const proxySetSessionState = async newState => {
        let calculatedState = newState;
        if (typeof newState === 'function') {
            const prev = _p.sessionStorage.getKey(storageKey);
            calculatedState = newState(prev);
        }
        if (calculatedState == null) {
            _p.sessionStorage.deleteKey(storageKey);
        } else {
            _p.sessionStorage.setKey(storageKey, calculatedState);
        }
        publishGlobalChangeDirect(CHANGE_TYPE, { storageKey, newState: calculatedState });
    };

    if (duration === DURATION.ALWAYS) {
        await proxySetPersistentState(value);
    } else if (duration === DURATION.SESSION) {
        await proxySetSessionState(value);
    } else if (duration === DURATION.REQUEST) {
        throw new Error('setDirect does not currently support duration:"REQUEST"');
    }
};
/**
 * This allows getting data "directly" into the appropriate places without using a hook,
 * but still getting the protection of generating keys in the same fashion as the hook
 * @param {string} stateName - name of state to obtain
 * @param {retention} retention
 * @param {string} [routeStateKey] - routeStateKey, only required when retention.scope === SCOPE.PER_ROUTE
 */
useBbState.getDirect = async function getDirect(stateName, retention, routeStateKey) {
    const {
        id,
        scope = SCOPE.LOCAL,
        visibility = VISIBILITY.PER_BROWSER,
        duration = DURATION.REQUEST
    } = retention || {};
    const storageKey = scopeToKey(scope, stateName, id, routeStateKey);

    //validate and throw errors as needed:
    validate(visibility, duration, scope, id);

    if (duration === DURATION.ALWAYS) {
        return _p.localStorage.getKey(storageKey, undefined, undefined, false);
    } else if (duration === DURATION.SESSION) {
        return _p.sessionStorage.getKey(storageKey);
    } else if (duration === DURATION.REQUEST) {
        throw new Error('getDirect does not currently support duration:"REQUEST"');
    }
};

/**
 * Validate if the given combination of parameters is both implemented and correct:
 * @param {*} visibility
 * @param {*} duration
 * @param {*} scope
 * @param {*} id
 */
function validate(visibility, duration, scope, id) {
    if (visibility !== VISIBILITY.PER_BROWSER) {
        throw new Error(`Retention visibility of "${visibility}" has not been implemented yet`);
    }
    if (duration === DURATION.REQUEST && scope !== SCOPE.LOCAL) {
        throw new Error('For durations of "request" only a scope of "local" is supported.');
    }

    if (scope === SCOPE.LOCAL && duration !== DURATION.REQUEST && id == null) {
        throw new Error(
            'An "id" must be included in the retention options for useBbState if the scope is "local" and the duration is not equal to "request".'
        );
    }
}

function scopeToKey(scope, stateName, id, routeStateKey) {
    switch (scope) {
        case SCOPE.GLOBAL:
            return stateName;
        case SCOPE.LOCAL:
            return `${stateName}|${id}`;
        case SCOPE.PER_ROUTE:
            if (!routeStateKey) {
                throw new Error('PER_ROUTE scope requires a routeStateKey.');
            }
            return `${stateName}|${routeStateKey}`;
        default:
            throw new Error(`${scope} is not a valid retention scope.`);
    }
}

useBbState._private = _private;
export default useBbState;
