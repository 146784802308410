import lodash from 'lodash';
const { omit } = lodash;

/**
 *
 * @param {{
 *    tagId:string,
 *    sensorType:string,
 *    rssi?:number,
 *    inDatabase?:boolean,
 * }} newEntry
 * @param {Array} dbResults
 * @param {{
 *    displayGatheredErrors:function,
 *    titularField:string,
 *    publish:function,
 *    request:function,
 *    matchProperties:Array<string>,
 *    displayInAscii:boolean,
 *    dataRights:import('../../../hooks/useUserRole').Role
 *    mergeNamespace:string,
 *    mergeRelation:string,
 *    unknownTagAction:constants.tagAction,
 *    inactiveTagAction:constants.tagAction,
 *    beepForNewReads:boolean
 * }} config
 * @param {function} update
 * @returns {Promise<boolean>} True if the grid entry was updated, false if it was removed
 */
async function merge(entry, dbResults, config, update) {
    let newEntry = {
        ...dbResults[0],
        ...omit(entry, ['rssi', 'batteryLevel']),
        // hold onto original database unique row id so it can be used
        // for database updates later (see willUpdate_namespace_relation).
        $dbLoki: dbResults[0].$loki,
        _id: dbResults[0]._id
    };
    newEntry.inDatabase = true;
    update(newEntry);
    return true;
}

export default { merge };
