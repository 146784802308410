import { useContext } from 'react';
import UserContext from '../contexts/UserContext';
const EMPTY_ARRAY = [];

/**
 * Returns the feature flags the current user has access to for the current
 * tenant and use case.
 * If the user has not selected a tenant yet, noReally_AllOfThem is the only way to determine if
 * a feature flag is enabled.
 * TODO: This won't really be accurate if the feature flag should be limited in some
 * way (by use case or tenant for instance), but we do not have the info to apply the
 * restriction if this is before the tenant selection.  It would be better if identity
 * silo could determine if the feature flag is global and apply it in the allFeatureFlags
 * for us.
 * @param {boolean} noReally_AllOfThem Search all tenant/use case combos for feature flags
 * @returns Array<string>
 */
export default function useFeatureFlags(noReally_AllOfThem = false) {
    const user = useContext(UserContext) ?? { allFeatureFlags: EMPTY_ARRAY };
    return (
        user.allFeatureFlags ??
        (!noReally_AllOfThem
            ? EMPTY_ARRAY
            : user?.user?.tenant
                  .map(t => {
                      return t.useCase.map(u => {
                          return u.featureFlags;
                      });
                  })
                  .flat(Infinity) ?? EMPTY_ARRAY)
    );
}
