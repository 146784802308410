import logging from '@sstdev/lib_logging';
import globalConfig from '../globalConfig/index';
import * as bridge from './bridgeLogger';
import constants from '../constants';

function configureLogging(user) {
    const config = globalConfig();
    logging.setMinLogLevel(config.minLogLevel);
    if (config.logType & logging.outputType.BRIDGE) {
        logging.setBridgeLogger(bridge);
    }
    logging.setOutputType(config.logType);
    logging.setEnvironment(config.environment);
    logging.setRelease(config.logRelease);
    logging.setTenantId(user?.activeTenantId ?? '?');
    logging.setUserId(user?._id ?? '?');
    logging.setUseCaseTitle(user?.activeUseCase?.['metaui:useCase']?.title ?? '?');

    const ucValues = overrides[user?.activeUseCase?.['metaui:useCase']?.id] ?? overrides.default;
    logging.setSampleRate(ucValues.sampleRate);
    logging.setIgnoredErrors(IGNORED_ERRORS);
}

const overrides = {
    [constants.useCaseIds.FREIGHT_RUNNER]: {
        // https://docs.sentry.io/platforms/javascript/configuration/sampling/
        sampleRate: 0.25
    },
    default: {
        sampleRate: 1
    }
};

// ALL errors that include any of the following strings will be ignored, and not sent to Sentry:
// If you need to filter by exact match, use a regex pattern instead.
// https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-ignore-errors
const IGNORED_ERRORS = [
    // common login errors:
    'Unknown User or Incorrect Password',
    'Email is required',
    // user errors we don't care about
    'Unable to submit scan.',
    'Unable to delete built-in role',
    // logged multiple times in different ways:
    '[RE]Error: doingUpdate_application_useCase failed. - Error saving database',
    '[RE]Error: == Workflow failed for update_application_useCase == - Error saving database'
];
export default configureLogging;
