import {
    createElement as rc,
    createContext,
    useMemo,
    useEffect,
    useCallback,
    useContext,
    useRef,
    useState
} from 'react';
import { constants, metadata } from 'lib_ui-services';
import useEventSink from '../../../hooks/useEventSink';
import mergeReads from './mergeReads';
import { hooks } from 'lib_ui-primitives';
import useNotification from '../../../hooks/useNotification';
import useReads from '../../../hooks/useReads';
import useUserRole from '../../../hooks/useUserRole';
import useHasFeatureFlag from '../../../hooks/useHasFeatureFlag';

const { useDebounceGatheringInputs, usePersistentState } = hooks;
const EMPTY_ARRAY = [];
const { REMOVE, REMOVE_AND_ERROR } = constants.tagAction;
export const MergedReadContext = createContext();
export function useMergedReads() {
    return useContext(MergedReadContext);
}
const _p = {
    useReads,
    mergeReads,
    useUserRole,
    THROTTLE_VIEW_CHANGES: 200
};
export const _private = _p;
export default function MergedReadProvider(props) {
    const {
        errorNotificationDebounceMils = 800,
        hNode: {
            id,
            namespace,
            relation,
            hNodeType,
            matchProperties,
            unknownTagAction,
            inactiveTagAction,
            beepForNewReads
        }
    } = props || { hNode: {} };

    const displayInAscii = useHasFeatureFlag('displayInAscii');
    const [role, roleIsReady] = _p.useUserRole();
    const mergeRelation = relation.split('-')[0];
    const titularField = useMemo(
        () => metadata.getTitleAlternative(namespace, mergeRelation),
        [namespace, mergeRelation]
    );
    if (!Array.isArray(matchProperties)) {
        throw new Error(
            `matchProperties must be an array.  A ${
                hNodeType ?? 'Transaction form or List node'
            } type block might not be setup correctly.`
        );
    }

    const [includeUnknownTags, setIncludeUnknownTags] = usePersistentState(
        `includeUnknownTags${id}`,
        ![REMOVE, REMOVE_AND_ERROR].includes(unknownTagAction)
    );

    const { reads } = _p.useReads(undefined);
    const [subscribe, publish, request] = useEventSink();
    const [view, setView] = useState();
    const mergedReads = useRef(EMPTY_ARRAY);

    const displayError = useNotification({ isError: true });
    const displayGatheredErrors = useDebounceGatheringInputs(
        displayError,
        [displayError],
        errorNotificationDebounceMils
    );

    // Encapsulate because it has so many dependencies (none of which change).
    const config = useMemo(() => {
        return {
            id,
            publish,
            request,
            matchProperties,
            beepForNewReads,
            beepForUnknownReads: beepForNewReads && ![REMOVE, REMOVE_AND_ERROR].includes(unknownTagAction),
            displayInAscii,
            dataRights: role.dataRights,
            mergeNamespace: namespace,
            mergeRelation,
            unknownTagAction,
            inactiveTagAction,
            titularField,
            displayGatheredErrors
        };
    }, [
        id,
        publish,
        request,
        matchProperties,
        beepForNewReads,
        unknownTagAction,
        displayInAscii,
        role.dataRights,
        namespace,
        mergeRelation,
        inactiveTagAction,
        titularField,
        displayGatheredErrors
    ]);

    useEffect(() => {
        if (!roleIsReady || reads == null) return;
        const view = reads.getFilteredView(
            includeUnknownTags ? { inDatabase: { $exists: true } } : { inDatabase: true },
            _p.THROTTLE_VIEW_CHANGES,
            ['inDatabase', titularField, 'tagId']
        );
        // Does not trigger a rerender - onChange recipient is responsible for that.
        const unsubscribe = view.subscribeToChange((changedView, batch) => {
            mergedReads.current = changedView;
            setView(changedView);
            _p.mergeReads(batch, config, changedView.update, changedView.remove);
        });
        mergedReads.current = view;
        setView(view);
        // cleans up subscriptions, etc.
        return () => {
            unsubscribe();
            view.destroy();
        };
        // only interested in first value of includeUnknownTags, filter will be changed elsewhere
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [config, reads, titularField, roleIsReady]);

    useEffect(() => {
        if (view == null) return;
        view.changeFilter(includeUnknownTags ? { inDatabase: { $exists: true } } : { inDatabase: true });
    }, [includeUnknownTags, view]);

    const removeEntry = useCallback(
        entry => {
            reads.remove('tagId', entry.tagId);
        },
        [reads]
    );

    // Keep track of unmounting so async ops don't change state after unmounting;
    const canUpdateState = useRef(true);
    useEffect(() => {
        return () => (canUpdateState.current = false);
    }, []);

    const reset = useCallback(() => {
        // rules engine cares about sensorType when publishing to sensor services
        // (e.g. zebraRfidService.js), but ReadProvider does not and will clear
        // all the reads.
        publish({ sensorType: constants.sensorTypes.RFID }, { verb: 'reset', namespace: 'sensor', relation: 'read' });
    }, [publish]);

    useEffect(() => {
        const unsubscribes = [];
        // in case this is an item-transaction or similar.
        if (mergeRelation !== relation) {
            unsubscribes.push(subscribe({ verb: 'cancel', namespace, relation, status: 'success' }, reset));
            unsubscribes.push(subscribe({ verb: 'submit', namespace, relation, status: 'success' }, reset));
        }
        return () => unsubscribes.forEach(u => u());
    }, [namespace, mergeRelation, relation, subscribe, reset]);

    const mergeNewRecord = useCallback(
        payload => {
            const { newRecord } = payload;
            if (newRecord?.tagId != null) {
                const matchingRead = reads.getByUniqueField('tagId', newRecord.tagId);
                if (matchingRead != null) {
                    // allow matching read to be reevaluated by mergeReads
                    const newRead = { ...matchingRead };
                    delete newRead.inDatabase;
                    reads.update(newRead);
                }
            }
        },
        [reads]
    );

    useEffect(() => {
        const unsubscribes = [
            subscribe({ verb: 'remove', namespace, relation: mergeRelation, status: 'success' }, removeEntry),
            subscribe({ verb: 'create', namespace, relation: mergeRelation, status: 'success' }, mergeNewRecord)
        ];
        return () => unsubscribes.forEach(u => u());
    }, [namespace, mergeRelation, subscribe, removeEntry, mergeNewRecord]);

    // If reads contain RFID, then these reads will display on the intermediate screen and the
    // reads should not be reset when a user cancels/submits a detail screen.  Otherwise,
    // cancel/submit will take the user back to the first screen and reads should be
    // reset so that performing the same read after a cancel/submit will not error.
    useEffect(() => {
        const resetIfNotRfid = () => {
            const allReads = reads.get();
            if (!allReads.some(r => [constants.sensorTypes.RFID, constants.sensorTypes.BLE].includes(r.sensorType))) {
                reset();
            }
        };
        const unsubscribes = [
            subscribe({ verb: 'cancel', namespace, relation: mergeRelation, status: 'success' }, resetIfNotRfid),
            subscribe({ verb: 'submit', namespace, relation: mergeRelation, status: 'success' }, resetIfNotRfid)
        ];
        return () => unsubscribes.forEach(u => u());
    }, [namespace, mergeRelation, subscribe, reads, reset]);

    const context = useMemo(() => {
        return {
            namespace,
            relation,
            mergeRelation,
            mergeNamespace: namespace,
            matchProperties,
            setIncludeUnknownTags,
            toggleIncludeUnknownTags: () => setIncludeUnknownTags(prev => !prev),
            includeUnknownTags,
            removeEntry,
            reset,
            subscribeToChange: view?.subscribeToChange,
            beepForNewReads
        };
    }, [
        namespace,
        relation,
        mergeRelation,
        matchProperties,
        setIncludeUnknownTags,
        includeUnknownTags,
        removeEntry,
        reset,
        beepForNewReads,
        view
    ]);

    return rc(MergedReadContext.Provider, { value: context }, props.children);
}
