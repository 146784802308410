import { createElement as rc, useState, useEffect } from 'react';
import blockChain from '@sstdev/lib_block-chain';
import HNode from '../../../HNode';
import ReadProvider from '../../contextProviders/ReadProvider';
import '@sstdev/lib_block-chain/dist/types/blockNamespace';
import '@sstdev/lib_block-chain/dist/types/basic';
import '@sstdev/lib_block-chain/dist/types/hasAccessModifiers';

function HavenConfig() {
    const [hNode, setHNode] = useState();
    useEffect(() => {
        const asyncGenerateHNode = async () => {
            const hNode = await generateHNode();
            setHNode(hNode);
        };
        asyncGenerateHNode();
    }, []);
    if (!hNode) return null;
    // prettier-ignore
    return rc(ReadProvider, {desiredSensorTypes: ['NONE'], active: false, id: 'haven-config'},
        rc(HNode, { hNode, currentRoute: 'select' })
    );
}

let hNode;
function generateHNode() {
    if (hNode == null) {
        const layout = new blockChain.layout.LeftAlignLayout(
            '',
            {},
            {
                namespaces: [
                    {
                        prettyName: 'Application',
                        title: 'application',
                        relations: [{ prettyName: 'Component', title: 'component' }]
                    },
                    {
                        prettyName: 'Security',
                        title: 'security',
                        relations: [{ prettyName: 'Role', title: 'role' }]
                    },
                    {
                        prettyName: 'Deploy',
                        title: 'deploy',
                        relations: [{ prettyName: 'Environment', title: 'environment' }]
                    },
                    {
                        prettyName: 'Meta UI',
                        title: 'metaui',
                        relations: [{ prettyName: 'Use Case', title: 'useCase' }]
                    }
                ]
            }
        );
        layout.accessModifier_HiddenFor({ hiddenfor: 'user' });
        layout.accessModifier_HiddenFor({ hiddenfor: 'admin' });
        const form = layout.form_SingleRecordForm('', {
            forAction: 'new',
            namespace: 'deploy',
            relation: 'environment'
        });
        form.formElement_ShortText('Title', {
            propertyName: 'title',
            required: true
        });
        form.formElement_InvisibleElement('isHaven', {
            propertyName: 'isHaven',
            overrideDataType: 'boolean'
        });
        form.formElement_ShortText('Release', {
            propertyName: 'release',
            required: true,
            tooltip: 'Enter a release title from One Touch or leave <Most Recent> to use the most recent release.'
        });
        form.formElement_DatePicker('Destroy Time', {
            propertyName: 'destroyTime',
            required: true,
            // Destroy haven in 12 hours using willNew_deploy_environment.
            tooltip: 'Time when haven will be destroy (default in 12 hours).'
        });
        const tenant = form.formElement_EditableList('Add Tenant', {
            propertyName: 'tenant',
            required: true
        });
        tenant.formElement_ShortText('Title', {
            propertyName: 'title',
            required: true
        });
        const useCase = tenant.formElement_EditableList('Add Use Case', {
            propertyName: 'useCase',
            required: true
        });
        useCase.formElement_DropDown('Use Case', {
            propertyName: 'title',
            namespace: 'metaui',
            relation: 'useCase',
            required: true,
            dropdownDisplayProperties: ['title'],
            inputDisplayProperties: ['title']
        });
        form.formButton_FormButton('Create', {
            buttonStyle: 'Positive',
            formAction: 'submit'
        });

        hNode = layout.renderJson();
    }
    return hNode;
}

export default HavenConfig;
HavenConfig.generateHNode = generateHNode;
