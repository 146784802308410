import { createElement as rc } from 'react';
import { Caret, fromTheme, h2, View, Pressable, styled, webOnlyStyles, nativeOnlyStyles } from 'lib_ui-primitives';

import useToggle from '../../../hooks/useToggle';
import Collapse from '../../helpers/Collapse';
import HavenConfig from './HavenConfig';

let JitWrapper = styled(View).attrs(props => ({
    name: 'tenant-wrapper',
    ['aria-expanded']: !props.isCollapsed
}))`
    display: flex;
    flex-direction: column;
`;

JitWrapper = webOnlyStyles(JitWrapper)`
cursor: pointer;
flex-shrink:0;
:not(:first-of-type) {
    margin-top: 16px;
}
`;
JitWrapper.displayName = 'JitWrapper';

let JitTitle = styled(Pressable).attrs({ name: 'tenant-title' })`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
JitTitle = nativeOnlyStyles(JitTitle)`
margin-top: ${fromTheme('viewMargin')};
margin-bottom: ${fromTheme('viewMargin')};
`;
JitTitle.displayName = 'TenantTitle';

const ToggleCaret = styled(Caret).attrs({ name: 'toggle-caret' })`
    margin-right: 6px;
    height: 8px;
    width: 8px;
`;
ToggleCaret.displayName = 'ToggleCaret';

const WrappedCollapse = styled(Collapse)`
    flex-wrap: wrap;
    margin: 0 ${({ theme }) => (theme.mobile ? 0 : '60px')};
`;
WrappedCollapse.displayName = 'WrappedCollapse';

let LineDivider = styled(View)`
    flex-grow: 1;
    border-left-width: 0;
    border-right-width: 0;
    border-top-width: 0;
    border-bottom-width: 1px;
    border-bottom-color: ${fromTheme('borderColor')};
    margin-left: 16px;
    height: 3px;
`;
LineDivider = webOnlyStyles(LineDivider)`
    border-style: solid;
`;
LineDivider.displayName = 'LineDivider';

/** @type {import('react').FC} */
function Haven() {
    const [isCollapsed, toggleCollapse] = useToggle(true);
    // prettier-ignore
    return rc(JitWrapper, { isCollapsed },
        rc(JitTitle, { onClick: () => toggleCollapse(x => !x) },
            rc(ToggleCaret, { isOpen: !isCollapsed, }),
            rc(h2, null, 'Create Haven')
        ),
        rc(WrappedCollapse, { isCollapsed },
            rc(HavenConfig)
        )
    );
}

export default Haven;
