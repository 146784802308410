import logging from '@sstdev/lib_logging';
import ObjectId from './ObjectId';
import match from './contextMatching';
import lodash from 'lodash';
const { omit } = lodash;

const CONTEXT_EXCLUDED_FIELDS = ['onError', 'logic', 'prereqs', 'description'];
let shouldLogEvents = false;
let maxPayloadLogLength = 250;
// You can trace event subscriptions/publications/unsubscriptions by putting a
// context object in the traceEvents array below.
// If you use traceEvents, search console with something like this
// /EVENTSINK.TRACE.*/
// Or if you put debugText on your publish/subscribe calls, like this:
// /EVENTSINK.TRACE.*<your debug text here>/
// Example: const traceEvents = [{ verb: 'cancel', namespace: 'item', relation: 'item' }];
const traceEvents = [];

let queue = [];

let running = false;
export function clearQueue() {
    queue = [];
}
export const _private = {
    enqueue,
    queue,
    contextMatchesFilter,
    waitingForSubscribers: [],
    clearQueue
};
export function logEvents(enable) {
    shouldLogEvents = enable;
}

//  const { enqueueHandlers = true, debugDetail, bubbleAlways = false, preventBubble = false, waitForSubscriber = false }
/**
 * @typedef {() => void} UnsubscribeFunction
 * @typedef {{enqueueHandlers:boolean, debugDetail:any, bubbleAlways:boolean, preventBubble:boolean, waitForSubscriber:true}} PublishOptions
 *
 * @typedef {(context: object, callback: (action: any) => any) => UnsubscribeFunction} SubscribeFunction
 * @typedef {(payload: object, context: object, options?: PublishOptions) => Promise} PublishFunction
 * @typedef {(payload: object, context: object, timeOutMs?: number, errorWhenNoSubscribers: boolean = true, options?: PublishOptions) => Promise<any>} RequestFunction
 *
 * @typedef {[SubscribeFunction, PublishFunction, RequestFunction]} EventSink [subscribe, publish, request]
 *
 * @typedef {Object} EventSinkProps
 * @property {{[key: string]: ((action: any) => void)[] }} subscriptions container for the event subscriptions in the current context
 * @property {boolean} [logEvents=false]
 * @property {EventSink} [parentSink]
 * @property {string} [name]
 *
 * @typedef {(props: EventSinkProps) => EventSink} EventSinkCreator
 */

function contextMatchesFilter(context, filter) {
    if (filter == null) return true;
    if (Array.isArray(filter)) {
        return filter
            .map(f => {
                return contextMatchesFilter(context, f);
            })
            .some(allowed => allowed);
    }
    return Object.entries(filter).every(([key, value]) => {
        return context[key] === value;
    });
}
/**
 * Create a super simple event sink
 * @type {EventSinkCreator}
 */

export default function eventSink(eventBoundaryContext) {
    let { subscriptions = [], logEvents = false, parentSink, name, subscriptionFilter } = eventBoundaryContext;
    name = name ? `(${name}) ` : '';
    shouldLogEvents = logEvents;

    /** @type {SubscribeFunction} */
    function subscribe(rawContext, callback, debugDetail) {
        if (typeof rawContext === 'function') {
            throw new Error(
                "For subscribe, you've put the callback into the first parameter.  It should be the second."
            );
        }
        if (typeof rawContext === 'string') {
            const [verb, namespace, relation, status] = rawContext.split('_');
            const exampleObject = { verb, namespace, relation, status };
            throw new Error(
                `Subscribe now takes a context object instead of a string for the first argument.  Replace ${rawContext} with ${JSON.stringify(
                    exampleObject
                )}.`
            );
        }
        //basic validation of eventName
        const context = omit(rawContext, CONTEXT_EXCLUDED_FIELDS);
        if (!contextMatchesFilter(context, subscriptionFilter)) {
            if (parentSink) {
                const [subscribeInParent] = parentSink;
                return subscribeInParent(rawContext, callback, debugDetail);
            }
            return;
        }
        if (Object.keys(context).length === 0) {
            throw new Error(
                'Your subscription context needs at least one context field (e.g. verb, namespace, relation, etc.)'
            );
        }
        const contextString = getContextString(context);
        //do the actual subscription
        //do the actual subscription
        subscriptions[contextString] = subscriptions[contextString] || [];
        // Get the event subscriptions for this particular context;
        const eventSubscriptions = subscriptions[contextString];
        // This is weird, but store the actual context as a property on the array to speed things up.
        eventSubscriptions.context = context;
        // Now add this subscribe call's callback.
        eventSubscriptions.push(callback);

        traceEvents.forEach(eventContext => {
            if (JSON.stringify(eventContext) === contextString) {
                logging.debug(`[EVENTSINK TRACE]${name} SUBSCRIBING ${contextString} | ${debugDetail}`);
            }
        });

        const waitingMatches = _private.waitingForSubscribers.filter(w => match(context, w.context));
        waitingMatches.forEach(w => {
            publish(w.payload, w.context);
            _private.waitingForSubscribers.splice(_private.waitingForSubscribers.indexOf(w), 1);
        });

        // return an unsubscribe function
        return () => {
            traceEvents.forEach(eventContext => {
                if (JSON.stringify(eventContext) === contextString) {
                    logging.debug(`[EVENTSINK TRACE]${name} UNSUBSCRIBING ${contextString} | ${debugDetail}`);
                }
            });
            eventSubscriptions.splice(eventSubscriptions.indexOf(callback), 1);
        };
    }

    /** @type {PublishFunction} */
    async function publish(payload, rawContext, options = {}) {
        const {
            enqueueHandlers = true,
            debugDetail,
            bubbleAlways = false,
            preventBubble = false,
            waitForSubscriber = false
        } = options;
        if (typeof payload === 'string') {
            const [verb, namespace, relation, status] = payload.split('_');
            const exampleObject = { verb, namespace, relation, status };
            throw new Error(
                `publish now takes a context object (in the second parameter) instead of a string for the first argument.  Replace ${payload} with ${JSON.stringify(
                    exampleObject
                )}.`
            );
        }
        const context = omit(rawContext, CONTEXT_EXCLUDED_FIELDS);
        const { verb, namespace, relation, status } = context;
        if (Object.keys(context).length === 0) {
            const contextString = getContextString(rawContext);
            const payloadString = getContextString(payload);
            const optionsString = getContextString(options);
            throw new Error(
                `Your publish context needs at least one context field (e.g. verb, namespace, relation, etc.)}.  Context: ${contextString} | Payload: ${payloadString} | Options: ${optionsString}. `
            );
        }
        if (
            status === 'failure' &&
            (payload.errors == null || payload.errors.field == null || payload.errors.form == null)
        ) {
            throw new Error('A failure publication must contain an `errors` object in the form: {field:{}, form:[]}');
            //TODO: the code commented out below is NOT true: We have several success publications without .result.
            //Should we re-evaluate that for a more consistent API?
            // } else if (status === 'success' && payload.result == null) {
            //     logging.warn(
            //         `A success publication must contain a 'result' object. You published ${verb}_${namespace}_${relation}${
            //             status ? '_' + status : ''
            //         } without.`
            //     );
        } else if (status !== 'success' && !verb?.startsWith('did') && payload?.result) {
            logging.warn(
                `Unless a publication is a 'success', it should not include a '.result'. You published ${verb}_${namespace}_${relation}${
                    status ? '_' + status : ''
                }`
            );
        }

        const contextString = getContextString(context);
        if (shouldLogEvents) {
            try {
                let logPayload = typeof payload === 'object' ? JSON.stringify(payload, trimJSON, 3) : payload;
                if (logPayload.length > maxPayloadLogLength) {
                    logPayload = logPayload.substr(0, maxPayloadLogLength) + '...\n   ...\n }';
                }
                logging.info(
                    `[EVENTSINK]${name}\n type: '${contextString}',\n payload: ${logPayload}, options: ${JSON.stringify(
                        options
                    )}`
                );
            } catch (err) {
                // probably a payload serialization problem
                logging.error(err);
                logging.error(
                    `[EVENTSINK]${name}\n You may have had a payload serialization error (like a circular ref maybe).`
                );
            }
        }
        let eventSubscriptions = Object.values(subscriptions).filter(subscription =>
            match(subscription.context, context)
        );
        // Reserve chosen contexts for tracing
        const matchedSubscriptionContexts = eventSubscriptions.map(s => s.context);
        // Flatten all the subscription functions arrays into one array.
        eventSubscriptions = eventSubscriptions.flat().filter(x => !!x);
        // eslint-disable-next-line no-undef
        if (!__PRODUCTION__ && !status && !eventSubscriptions.length && !parentSink && !waitForSubscriber) {
            logging.warn(`[EVENTSINK]${name}'${contextString}' was published, but nothing is listening`);
        }
        const handled = !!eventSubscriptions.length;

        try {
            if (handled) {
                traceEvents.forEach(eventContext => {
                    if (JSON.stringify(eventContext) === contextString) {
                        logging.debug(
                            `[EVENTSINK TRACE]${name} PUBLISHING  to ${eventSubscriptions.length} subscriptions: ${contextString}. | ${debugDetail}`
                        );
                        logging.debug(
                            `[EVENTSINK TRACE]${name}     Matched subscriptions: ${JSON.stringify(
                                matchedSubscriptionContexts
                            )}`
                        );
                    }
                });
            }

            // Sort in-place by priority with highest priority first
            // e.g priorities in this order: [2,null,1,undefined,4]
            // would end up in this order: [4,2,1,null,undefined]
            eventSubscriptions.sort((a, b) => b.priority - a.priorty);

            const results = await Promise.all([
                Promise.resolve(), //always resolve at least 1
                ...eventSubscriptions.map(callback => {
                    try {
                        // Enqueue ensures any publish calls inside the task are sequenced at the end
                        // of the current stack.
                        // Enqueue will return a promise that resolves when the task is initiated.
                        // It does not wait for the task to finish because that could block the queue.
                        let enqueueCallback = () => callback(payload, { eventBoundaryContext, ...rawContext });
                        // These next two lines add a name to the callback method so that it is easier
                        // to see in the stack.
                        const assuredName = `${verb}_${namespace}_${relation}${status ? '_' + status : ''}_subscriber`;
                        Object.defineProperty(enqueueCallback, 'name', { value: assuredName, writable: false });
                        return enqueueHandlers
                            ? //  allow eventBoundaryContext to be overwritten by original (in case this is bubbled from a different one)
                              enqueue(enqueueCallback)
                            : callback(payload, rawContext);
                    } catch (error) {
                        logging.error(error);
                    }
                })
            ]);

            if (bubbleAlways || !handled) {
                if (parentSink && !preventBubble) {
                    logging.debug(
                        `[EVENTSINK]${name} 'Bubbling ${contextString}' up to parent with options ${JSON.stringify(
                            options
                        )}`
                    );
                    const [, bubbleUpToParent] = parentSink;
                    return bubbleUpToParent(payload, { eventBoundaryContext, ...rawContext }, options);
                } else if (!handled && waitForSubscriber) {
                    logging.debug(`[EVENTSINK]${name} 'Waiting for subscriber to ${contextString}'.`);
                    _private.waitingForSubscribers.push({ payload, context });
                }
            }
            return results;
        } catch (err) {
            // FYI - This may cause duplicate logging if a request() is rejected and the
            // corresponding _failure event is also handled elsewhere and logged.
            // On the other hand, this will also capture other types of failures, so it's
            // probably a good idea to keep it around.
            if (err._isUserError) {
                logging.info(err.message);
            } else {
                logging.error(err);
            }
        }
    }
    // eslint-disable-next-line no-undef
    const defaultTimeout = __PRODUCTION__ ? 300000 : __UNIT_TESTING__ ? 500 : 15000; // 5 min in production, 15 seconds in dev, half a second when unit testing
    /** @type {RequestFunction} */
    async function request(
        payload,
        rawContext,
        timeOutMs = defaultTimeout,
        errorWhenNoSubscribers = true,
        options = {}
    ) {
        if (typeof payload === 'string') {
            const [verb, namespace, relation, status] = payload.split('_');
            const exampleObject = { verb, namespace, relation, status };
            throw new Error(
                `request now takes a context object (in the second parameter) instead of a string for the first argument.  Replace ${payload} with ${JSON.stringify(
                    exampleObject
                )}.`
            );
        }
        const context = omit(rawContext, CONTEXT_EXCLUDED_FIELDS);
        const { status } = context;
        if (status != null) {
            throw new Error(
                `You created an event request with a status (${status}) in the context.  That doesn't make much sense since it should fulfill the request promise when a status is published.`
            );
        }
        if (context.length === 0) {
            throw new Error(
                'Your request context needs at least one context field (e.g. verb, namespace, relation, etc.)'
            );
        }

        const contextString = getContextString(context);
        const eventSubscriptions = Object.values(subscriptions)
            .filter(subscription => match(subscription.context, context))
            .flat()
            .filter(x => !!x);

        if (!eventSubscriptions.length) {
            if (parentSink) {
                const [, , requestFromParent] = parentSink;
                return requestFromParent(payload, rawContext, timeOutMs, errorWhenNoSubscribers, options);
            }
            //if there is nothing listening, no need to even attempt to request
            if (errorWhenNoSubscribers) {
                return Promise.reject(
                    new Error(
                        `${name} No handlers registered for processing '${contextString}'. Unable to return a result.`
                    )
                );
            } else {
                return Promise.resolve(payload);
            }
        }

        // Use this eventId to match success and failures
        const eventId = new ObjectId().toString();

        let promise = new Promise((resolve, reject) => {
            let timeout;
            const unsubscribeSuccess = subscribe({ ...context, status: 'success' }, (payload, context) => {
                if (eventId === context.eventId) {
                    if (timeout) clearTimeout(timeout);
                    unsubscribeSuccess();
                    unsubscribeFailure();
                    resolve(payload);
                }
            });

            const unsubscribeFailure = subscribe({ ...context, status: 'failure' }, (payload, context) => {
                if (eventId === context.eventId) {
                    if (timeout) clearTimeout(timeout);
                    unsubscribeSuccess();
                    unsubscribeFailure();
                    // This check is not ideal because it makes the eventSink aware of a
                    // setupRulesEngine implementation detail.  Unfortunately, there
                    // isn't an easy way to enumerate all possible rulesEngine workflow
                    // matches so the eventSink cannot be given all possible subscriptions
                    // for the rulesEngine.
                    // This means the rules engine event subscription is going to match
                    // A LOT of publish contexts when there isn't actually a workflow
                    // that matches.
                    // This is a problem when the publication is waiting for a response
                    // because it will just sit and wait for a timeout (~5 minutes in prod)
                    // unless we reject the Promise like this code does.
                    if (payload.errors.form[0] === 'No workflow found.') {
                        if (eventSubscriptions.length === 1 && errorWhenNoSubscribers) {
                            return reject(
                                new Error(
                                    `${name} No handlers registered for processing '${contextString}'. Unable to return a result.`
                                )
                            );
                        }
                    }
                    reject(payload.originalError || payload.errors);
                }
            });

            if (options.timeOutMs || timeOutMs) {
                //create the error outside the timeout, so that the stack is actually helpful
                const timeoutError = new Error(
                    `No response received for ${contextString} after ${options.timeOutMs || timeOutMs}ms`
                );
                timeout = setTimeout(() => {
                    unsubscribeSuccess();
                    unsubscribeFailure();
                    if (errorWhenNoSubscribers) {
                        reject(timeoutError);
                    } else {
                        resolve();
                    }
                }, options.timeOutMs || timeOutMs);
            }
        });

        publish(payload, { ...rawContext, eventId }, { ...options, enqueueHandlers: false });

        return promise;
    }

    return [subscribe, publish, request];
}
eventSink._private = _private;
/**
 * Ensures a given task is run after any other previously enqueued tasks are initiated.
 *
 * The returned promise will be resolved when the enqueued task is initiated.
 * Enqueue will NOT wait for the task to complete.  It will not, for instance, await
 * a promise.  It will simply move the enqueued task to the end of the current stack.
 * This is because waiting on task completion creates all sorts of blocking problems,
 * including blocking of unrelated tasks.  If you want to know when a task is complete,
 * ensure the task publishes a success/failure and subscribe to it (also consider using request()
 * instead of publish()).
 * @param {function} task
 * @returns {object} promise
 */
async function enqueue(task) {
    // A task is already running.
    if (running) {
        // Create a promise to return to the caller.  This promise will be resolved
        // when the actual task starts.
        return new Promise((resolve, reject) => {
            // Enqueue the task, but wrap it with the promise resolver.
            queue.push(async function delayedExecution() {
                try {
                    resolve(task());
                } catch (err) {
                    logging.error(err);
                    reject(err);
                }
            });
        });
    } else {
        // No other task is running, so start the current one.
        try {
            running = true;
            task();
            // If some other tasks were added, start those now.
            while (queue.length > 0) {
                let nextRun = queue.shift();
                await nextRun();
            }
        } finally {
            running = false;
        }
    }
}
/**
 * Remove cruft and get a string representation of the context
 * @param {Object} context
 * @returns string
 */
export function getContextString(context) {
    const toDisplay = omit(context, ['eventBoundaryContext', 'user']);
    return JSON.stringify(toDisplay, trimJSON, 3);
}

//Nothing in here is really important, for any other reason than limiting the (console) log.
//So feel free to add or remove anything you want to see/hide in your console
const trimJSON = (key, value) => {
    if (typeof value === 'function') {
        return '<function>';
    }
    if (value == null) return '<undefined>';
    if (typeof value === 'object' && value.nativeEvent != null) return '<SyntheticEvent>';
    switch (key) {
        case 'hNode':
            return '<hNode>';
        case 'service':
            return '<service>';
        case '_private':
            return '<_private>';
        case 'tokenKey':
            return value.substring(0, 12) + '...';
        case 'config':
            return '{...}';
        case 'namespaces':
            return '{...}';
        case 'user':
            //this actually hides the tenant and useCase values inside it....
            return '{...}';
        case 'password':
            return '*'.repeat(value.length);
        case 'tenant':
        case 'useCase':
            if (Array.isArray(value)) return [value[0], '...'];
            break;
        default:
            break;
    }
    return value;
};
