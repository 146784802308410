import { constants } from 'lib_ui-services';
const { ERROR, REMOVE_AND_ERROR, REMOVE } = constants.tagAction;

/**
 *
 * @param {{
 *    tagId:string,
 *    sensorType:string,
 *    rssi?:number,
 *    inDatabase?:boolean,
 * }} newEntry
 * @param {Array} dbResults unused
 * @param {{
 *    displayGatheredErrors:function,
 *    titularField:string,
 *    publish:function,
 *    request:function,
 *    matchProperties:Array<string>,
 *    displayInAscii:boolean,
 *    dataRights:import('../../../hooks/useUserRole').Role
 *    mergeNamespace:string,
 *    mergeRelation:string,
 *    unknownTagAction:constants.tagAction,
 *    inactiveTagAction:constants.tagAction,
 *    beepForNewReads:boolean
 * }} config
 * @param {function} update unused
 * @param {function} remove
 * @returns {Promise<boolean>} True if the grid entry was updated, false if it was removed
 */
async function merge(newEntry, dbResults, config, update, remove) {
    if ([constants.sensorTypes.MANUAL, constants.sensorTypes.BARCODE].includes(newEntry.sensorType)) {
        // if it was some sort of explicit manual action (entry or barcode scan)
        if ([ERROR, REMOVE_AND_ERROR].includes(config.inactiveTagAction)) {
            //let the user know they can't have this record!
            const message = `${
                dbResults[0][config.titularField]
            } is an inactive record. You cannot perform a transaction on an inactive record.`;
            config.displayGatheredErrors(message);
        }
        if ([REMOVE, REMOVE_AND_ERROR].includes(config.inactiveTagAction)) {
            // don't include it in the grid:
            remove(newEntry);
        }
    } else {
        // If this is RFID just remove the inactive entry
        remove(newEntry);
    }
    // inactive records are NEVER displayed in the grid
    return false;
}

export default { merge };
