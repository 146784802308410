import isValidDate from 'date-fns/isValid';
import conversions from '@sstdev/lib_epc-conversions';
import fileImport from '../../../FORMS/importNamespace/importRelation/fileImport';

const _p = { loadFile: fileImport.getData };
export const _private = _p;

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

export default {
    verb: 'willCreate',
    namespace: 'import',
    relation: 'import',
    priority: 20, // before the default willCreate
    description: 'Load and Validate the data that is to be imported',
    logic: willCreate,
    onError
};

/**
 * @param {{
 *      error: Error;
 *      data: T;
 *      context: Context;
 *      dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>,
 *      workflowStack: WorkflowStack[]
 * }} parameters
 * */
function onError({ data, error, dispatch }) {
    const { newRecord } = data || {};
    const { foreignNamespace, foreignRelation } = newRecord || {};
    closeImportForm(dispatch, foreignNamespace, foreignRelation);
    throw error;
}

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function willCreate({ data, log, dispatch }) {
    const { newRecord } = data || { newRecord: {} };
    const {
        _id,
        title,
        firstRowContainsHeader,
        foreignNamespace,
        foreignRelation,
        columns,
        file: [file] = []
    } = newRecord;

    if (!_id) return;

    const startedAt = new Date();
    const rows = await _p.loadFile(file);
    const parsedData = parseImportData(rows, columns, firstRowContainsHeader, log, foreignNamespace, foreignRelation);

    closeImportForm(dispatch, foreignNamespace, foreignRelation);
    validateImportData(parsedData, title, log, dispatch);

    return {
        ...data,
        newRecord: {
            _id,
            title,
            firstRowContainsHeader,
            foreignNamespace,
            foreignRelation,
            columns,
            fileName: file.name,
            fileSize: file.size,
            startedAt,
            data: parsedData
        }
    };
}

function parseImportData(rows, columns, firstRowContainsHeader, log, foreignNamespace, foreignRelation) {
    return rows
        .map(transformRowToObject(columns, firstRowContainsHeader, log, foreignNamespace, foreignRelation))
        .filter(x => !!x);
}

function validateImportData(parsedData, title, log, dispatch) {
    if (parsedData.slice(0, 100).every(d => d.errors?.length)) {
        log.error(`Import "${title}" failed: No valid data in first 100 rows.`);

        dispatch(
            {
                message: `Import "${title}" failed: No valid data in first 100 rows. Import aborted.`,
                timeout: 5000,
                addToList: true,
                isError: true
            },
            { verb: 'pop', namespace: 'application', relation: 'notification' }
        );
        throw new Error(`Import "${title}" failed: No valid data in first 100 rows. Import aborted.`);
    }
}

function closeImportForm(dispatch, namespace, relation) {
    dispatch({}, { verb: 'close', namespace, relation });
}

export const transformRowToObject =
    (columns, firstRowContainsHeader, log, foreignNamespace, foreignRelation) => (row, rowNum) => {
        if (firstRowContainsHeader && rowNum === 0) return undefined;
        if (!row.some(x => x?.trim && x.trim())) return undefined;
        const result = columns.reduce((result, column, index) => {
            const def = column._meta;
            // don't do anything for skipped columns, or if there is no data for this column
            if (def.id === '_skip' || row.length <= index) return result;
            let key;
            try {
                if (def.isArrayElement) {
                    result[def.arrayProperty] = result[def.arrayProperty] || [];
                    result[def.arrayProperty][def.arrayIndex] = result[def.arrayProperty][def.arrayIndex] || {};
                    result[def.arrayProperty][def.arrayIndex][def.propertyName] = formatData(row[index], def);
                } else if (def.dataType && def.dataType.type === 'Lookup') {
                    key = `${def.foreignNamespace}:${def.originalRelation || def.foreignRelation}`;
                    result[key] = result[key] || {};
                    result[key][def.propertyName] = formatData(row[index], def);
                } else {
                    // Otherwise just assign the value
                    key = def.propertyName;
                    result[key] = formatData(row[index], def);
                }
            } catch (error) {
                const value = row[index];
                log.error(error);
                result.errors = result.errors || [];
                // if there is an error and the value fails validation, nothing is returned
                // so we need to add the value to the error object so the user can see what failed
                result.errors.push({
                    column: key,
                    message: error.message,
                    value
                });
            }

            return result;
        }, {});

        // Validate organization:person has either lastName or personId
        if (foreignNamespace === 'organization' && foreignRelation === 'person') {
            const { lastName, personId } = result;
            if (!lastName && !personId) {
                result.errors = result.errors || [];
                result.errors.push({
                    column: 'organization:person',
                    message: 'A Person must have either a Last Name or Person ID',
                    value: row
                });
                return result;
            }
        }

        return result;
    };

const formatData = (value, field) => {
    // Handle required fields
    if (field.required && !value && field.dataType?.type !== 'Boolean') {
        throw new Error(`Missing value for required field ${field.title}`);
    }

    // Trim string values
    const trimmedValue = typeof value === 'string' ? value.trim() : value;

    // Handle empty values for non-required fields
    // if it is not required, and nothing is there, don't complain.
    if (!trimmedValue && (!field.dataType || !['Boolean', 'Integer'].includes(field.dataType.type))) {
        return undefined;
    }

    // Check for scientific notation for sensitive fields (assetNo, serialNo, title)
    validateScientificNotation(trimmedValue, field);

    // Validate length constraints
    validateLengthConstraints(trimmedValue, field);

    // Handle fields without a specific data type
    if (!field.dataType || !field.dataType.type) {
        // no datatype => string, therefore, just return the data as string
        return trimmedValue?.toString();
    }

    // Handle Lookup fields, they should always be converted to strings
    if (field.dataType.type === 'Lookup') {
        return trimmedValue?.toString();
    }

    // Process specific data types
    switch (field.dataType.type) {
        case 'Hexadecimal':
            return validateHexadecimal(trimmedValue, field);
        case 'ASCII':
            return conversions.ascii.toHex(trimmedValue, true);
        case 'Currency':
            return processCurrency(trimmedValue, field);
        case 'Integer':
            return processInteger(trimmedValue, field);
        case 'Date/Time':
            return processDateTime(trimmedValue, field);
        case 'Email':
            return validateEmail(trimmedValue, field);
        case 'Boolean':
            return processBoolean(trimmedValue, field);
        default:
            return trimmedValue;
    }
};

// Helper functions to process and validate data types

const validateScientificNotation = (value, field) => {
    // Check for scientific notation for sensitive fields (assetNo, serialNo, title)
    const sensitiveFields = ['assetNo', 'serialNo', 'title'];
    if (
        typeof value === 'string' &&
        /^-?\d+(\.\d+)?[eE][+-]?\d+$/.test(value) &&
        sensitiveFields.includes(field.propertyName)
    ) {
        throw new Error(`${field.title} contains a value in scientific notation, which is not allowed for this field`);
    }
};

const validateLengthConstraints = (value, field) => {
    if (field.minLength && value.length < field.minLength) {
        const add = field.minLength < field.maxLength ? 'or more ' : '';
        throw new Error(`${field.title} should have ${field.minLength} ${add}characters`);
    }
    if (field.maxLength && value.length > field.maxLength) {
        const add = field.minLength < field.maxLength ? 'or less ' : '';
        throw new Error(`${field.title} should have ${field.maxLength} ${add}characters`);
    }
};

const validateHexadecimal = (value, field) => {
    const isValid = /^[0-9A-Fa-f]*$/g.test(value);
    if (!isValid) {
        throw new Error(`${field.title} does not contain a valid HEX value`);
    }
    return value;
};

const processCurrency = (value, field) => {
    if (typeof value === 'undefined') return undefined;
    const replaceable = new RegExp(`${getThousandsSeparator(value)}|£|€`, 'g');
    // `$` doesn't play nice in regexp for some reason. just do it separately
    const valueWithoutLocaleFormatting = value.toString().replace(replaceable, '').replaceAll('$', '');
    const parsed = Number(valueWithoutLocaleFormatting);
    if (isNaN(parsed)) {
        throw new Error(`${field.title} does not contain a valid numeric value. `);
    }
    validateNumericRange(parsed, field);
    return parsed;
};

const processInteger = (value, field) => {
    if (typeof value === 'undefined') return 0;
    const parsed = Number(value);
    if (isNaN(parsed)) {
        throw new Error(`${field.title} does not contain a valid Integer value. `);
    }
    validateNumericRange(parsed, field);
    return parsed;
};

const validateNumericRange = (value, field) => {
    if (typeof field.min !== 'undefined' && value < field.min) {
        throw new Error(`${field.title} should be more than or equal to ${field.min}. `);
    }
    if (typeof field.max !== 'undefined' && value > field.max) {
        throw new Error(`${field.title} should be less than or equal to ${field.max}. `);
    }
};

const processDateTime = (value, field) => {
    try {
        if (isValidDate(new Date(value))) {
            return new Date(value);
        } else {
            throw new Error(`${field.title} does not contain a valid Date/Time value`);
        }
    } catch (error) {
        throw new Error(`${field.title} does not contain a valid Date/Time value`);
    }
};

const validateEmail = (value, field) => {
    const isValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(value);
    if (!isValid) {
        throw new Error(`${field.title} does not contain a valid Email value`);
    }
    return value;
};

const processBoolean = (value, field) => {
    const stringValue = value?.toString()?.trim();
    // if the value is undefined, null, or an empty string, return the default value
    // for example, if a user is importing an asset that does not have the 'Active' column and value in the import file,
    // but the 'Active' toggle has a default value of true, then the asset will be created as active.
    if ([undefined, null, ''].includes(stringValue)) {
        return field.defaultValue !== undefined ? field.defaultValue : undefined;
    }

    return !/^(false|no|0)$/i.test(stringValue);
};

const getThousandsSeparator = value => {
    // ideally, look at the string, see if it is #,###.## format
    // or the non-us #.###,## format
    const stringValue = value.toString();
    const nonDigits = stringValue.replace(/\d/g, '');
    if (nonDigits.includes(',.')) {
        return ',';
    } else if (nonDigits.includes('.,')) {
        return '.';
    }
    // otherwise assume US
    return ',';
};
