import { constants } from 'lib_ui-services';
import logging from '@sstdev/lib_logging';
import lodash from 'lodash';
const { debounce } = lodash;
import mergeStrategies from './mergeStrategies';

// WARNING: If need RFID for non-unique location rooms or buildings *with non-unique tags*,
// this will not work - but then it's not clear how RFID would ever work in that
// scenario anyway so....
/**
 *
 * @param {Array<{
 *    tagId:string,
 *    sensorType:string,
 *    rssi?:number,
 *    inDatabase?:boolean,
 * }>} newEntries
 * @param {{
 *    displayGatheredErrors:function,
 *    titularField:string,
 *    publish:function,
 *    request:function,
 *    matchProperties:Array<string>,
 *    displayInAscii:boolean,
 *    dataRights:import('../../../hooks/useUserRole').Role
 *    mergeNamespace:string,
 *    mergeRelation:string,
 *    unknownTagAction:constants.tagAction,
 *    inactiveTagAction:constants.tagAction,
 *    beepForNewReads:boolean
 * }} config
 * @param {function} update
 * @param {function} remove
 * @returns {Promise<void>}
 */
export default async function mergeReads(newEntries, config, update, remove) {
    if (newEntries.length === 0) return;
    const { publish, dataRights, mergeNamespace, mergeRelation, beepForNewReads } = config;

    // Initialize before the loop to avoid the overhead on each iteration
    // and so that it can even be memoized between calls
    const dataAccess = await mergeStrategies.inaccessibleTag.memoizedAccessChecker(
        dataRights,
        mergeNamespace,
        mergeRelation
    );

    const updateWithErrorHandling = newEntry => {
        try {
            update(newEntry);
        } catch (err) {
            logging.error(err, '\nError while updating read merge entry.  Entry: ', JSON.stringify(newEntry));
        }
    };

    // All entries in a batch should be of the same sensorType
    const requestFromSensor = [
        constants.sensorTypes.RFID,
        constants.sensorTypes.BLE,
        constants.sensorTypes.BARCODE
    ].includes(newEntries[0].sensorType);

    for (let i = 0; i < newEntries.length; i++) {
        try {
            const entry = newEntries[i];
            if (entry.inDatabase == null) {
                const results = await lookupDbEntry(entry, config);

                // NOTE - the merge strategies are responsible for removing or updating the grid entry as applicable
                // If the grid was updated with a NEW merge of any sort, and beepDueToNewMerge is set to true
                // we will request a beep at the end of this loop
                let beepDueToNewMerge = false;
                switch (results.length) {
                    case 0: {
                        beepDueToNewMerge = await mergeStrategies.unknownTag.merge(
                            entry,
                            results,
                            config,
                            updateWithErrorHandling,
                            remove
                        );
                        break;
                    }
                    case 1: {
                        const actualRecord = results[0];
                        if (!dataAccess.hasAccess(actualRecord)) {
                            // if the user does not have access to the record, we don't need to worry about anything else
                            beepDueToNewMerge = await mergeStrategies.inaccessibleTag.merge(
                                entry,
                                results,
                                config,
                                updateWithErrorHandling,
                                remove
                            );
                        } else if (actualRecord.active === false) {
                            // if the user DOES have access, but the record is inactive:
                            beepDueToNewMerge = await mergeStrategies.inactiveRecord.merge(
                                entry,
                                results,
                                config,
                                updateWithErrorHandling,
                                remove
                            );
                        } else {
                            //Most common case: the record is active and the user has access:
                            beepDueToNewMerge = await mergeStrategies.recordOnFile.merge(
                                entry,
                                results,
                                config,
                                updateWithErrorHandling,
                                remove
                            );
                        }
                        break;
                    }
                    default: {
                        // result.length > 1
                        beepDueToNewMerge = await mergeStrategies.multipleMatch.merge(
                            entry,
                            results,
                            config,
                            updateWithErrorHandling,
                            remove
                        );
                        break;
                    }
                }

                if (beepDueToNewMerge) {
                    // eslint-disable-next-line no-undef
                    if (beepForNewReads && requestFromSensor && !__NO_SOUND__) {
                        _p.beepCount++;
                        _p.debouncedBeep(publish, Math.min(_p.beepCount, 10));
                    }
                }
            }
        } catch (err) {
            logging.error(err);
        }
    }
}

async function lookupDbEntry(entry, config) {
    const hexLookup = [constants.sensorTypes.RFID, constants.sensorTypes.BLE].includes(entry.sensorType);
    const criteria = { 'meta.deleted': { $exists: false } };
    const lookupValue = entry.tagId;
    // RFID/BLE should always have a tagId entry equal to the untransformed tagId
    if (hexLookup) {
        criteria.tagId = lookupValue;
    } else {
        //Manual or barcode entry. Match against matchProperties:
        criteria.$or = [];
        config.matchProperties.forEach(lookupField => {
            criteria.$or.push({ [lookupField]: lookupValue });
        });
    }
    const { result = [] } = await config.request(
        { criteria },
        {
            verb: 'get',
            type: 'find',
            namespace: config.mergeNamespace,
            relation: config.mergeRelation
        }
    );
    return result;
}

const _p = {
    debouncedBeep: debounce((publish, count) => {
        _p.beepCount = 0;
        publish(
            { message: 'New read', type: constants.notificationTypes.SOUND, times: count },
            {
                verb: 'pop',
                namespace: 'application',
                relation: 'notification'
            }
        );
    }, 1000),
    beepCount: 0
};
